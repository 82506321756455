



@media (max-width: 1024px) {
  .bark-left{
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    margin-left: -260px;
    margin-right: 25px;
    &.active{
      margin-left: 0px !important;
      margin-right: 0px;
      + .branch-column{

        pointer-events: none;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        filter: opacity(0.5) blur(2px) grayscale(1);
      }
    }
  }
  .burgerMenu{
    display: block !important;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
  }
  .bg0,.bg1,.bg2,.bg3,.bg4{
    background-position-x: 0 !important;
  }
}

// -Leaf Scrollbar- //
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #393738; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #4c4c4c; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #ee7f00; 
}
.logo{
  height: auto;
}
// -Parallax- //

.parallax{
height: 100vh;
    &.bg0{
     
        background-image: url(./assets/img/bgr_0.jpg) !important;
        background-attachment: fixed;
        background-position-y:7vh;
        background-position-x:10vw;
        background-repeat: no-repeat;   
        background-size: cover;
      
    }
    &.bg1{
     
        background-image: url(./assets/img/bgr_1.jpg) !important;
        background-attachment: fixed;
        background-position-y:7vh;
        background-position-x:10vw;
        background-repeat: no-repeat;
        background-size: cover;
      
    }
    &.bg2{
     
        background-image: url(./assets/img/bgr_2.jpg) !important;
        background-attachment: fixed;
        background-position-y:7vh;
        background-position-x:10vw;
        background-repeat: no-repeat;
        background-size: cover;
      
    }
    &.bg3{
      
        background-image: url(./assets/img/bgr_3.jpg) !important;
        background-attachment: fixed;
        background-position-y:7vh;
        background-position-x:10vw;
        background-repeat: no-repeat;
        background-size: cover;
      
    }
  }
// -Leaf Grid- //
html{
overflow: hidden;
}
button{
  &:focus{
    outline: none !important;
  }
}
body{
  margin: 0;
  background-color: #474546;
  //background-color: #393738;
  //height: 100vh;
  //overflow: hidden;

  a{
    text-decoration: none;
  }
  p{
    line-height: 1.5;
  }
}
.disabled{
  display: none !important;
}
.disabled2{
  height: 0 !important;
}
.enabled{
  display: flex !important;
}
small{
  color: #acb0b3;
  font-size: 0.7rem;
  font-weight: 400;
  display: block;
}
//Content-type = Container
//Root-Container
.root{
  display: flex;
  //overflow-y: scroll;
  overflow-x: auto;
  height: 100vh;
}
//Content-Container
.branch-row{
  width: 100%;
  flex-direction: row;
  display: flex;
  background-color: #474546;
  
  &:last-child{
    padding-bottom: 15vh;
  }
}
.branch-column{
  flex-direction: column;
  width: 100vw;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.branch{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: auto;
  //padding-bottom: 20vh;
  //padding-top: 20vh;
  width: 100%;
  overflow-x: hidden;

}
//Content-type = Content-box
.leafnopad{
  flex: 1 1 ;
  flex-grow: 1;
  flex-basis: 20rem;
  background-color: #393738;
  .introduction{
    padding-left: 30px;
    h1{
      color: #ee7f00;
    }
    p{
      color: #fafafa;
    }
  }
}
.leaf{
  flex: 1 1 ;
  flex-grow: 1;
  flex-basis: 20rem;
  padding: 20px 20px 40px 20px;
  .introduction{
    background-color: #393738;
    padding: 20px;
    h1{
      color: #ee7f00;
    }
    p{
      color: #fafafa;
    }
  }
  ul{
    background-color: #474546;
    padding: 20px;
    list-style: none;

    li{
      color: #393738;
      background-color: #ffffff;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
    }
    p{
      display: flex;
      flex: 1 1 auto;
      margin-right: 20px;
    }
    .interaction{
 
      display: flex;
      flex-direction: column;
      .edit{
        display: flex;
        flex-direction: row ;
        margin-bottom: 20px;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        border-bottom: solid 1px #393738;
        padding-bottom: 20px;
        &.editOff{
          display: none;
          transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
        }
        textarea{
          display: flex ;
          flex:1 1 auto ;
          background-color: #393738;
          color: #ffffff;
          padding: 20px;
          border: none;
          transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          outline-style: solid;
          outline-color: #393738;
          outline-width: 1px;
          &:focus{
            outline-color: #ee7f00;
            background-color: #393738;
            box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
            transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
          }
        }
        .icon{
          display:flex;
      
        }
        .icon-bgr{
          background-color: #393738;
          padding-right: 20px;
          padding-left: 20px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          .icon{
            display: flex;
            margin-top: 20px;
          }
          
        }
       

      }
      .icon{
        margin-bottom: 20px;
        background-color: #393738;
        height: 25px;
        width: 25px;
        color: #fafafa;
        border: solid 6px #474546;
        border-radius: 50%;
        border: 2px solid #ee7f00;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        img{
          height: 15px;
          width: 15px;
          padding: 5px;
          transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
        }
        &:last-child{
          border: 2px solid #285277;
        }
        &:hover{
          border: 2px solid #474546 ;
          background-color: #ee7f00; transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          &:last-child{
            border: 2px solid #474546 ;
            background-color: #285277;
            transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
}

// -Tree Helper- //

//Class
.blacken{
  background-color: #393738;
  color: #393738;
}
.textcenter{
  text-align: center;
}

// -Components- //

// Content-type = Menu
//Top-menu
.bark-top{
  z-index: 9999;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  justify-content: center;
  background-color: #fafafa;
  border-bottom: solid 8px #dbdddb;
  .content{   
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 0;
      padding: 0;
      &:first-child{
          border-left: solid 1px #dbdbdb; 
      }
      a{
          padding-left: 25px;
          padding-right: 25px;
          border-right: solid 1px #dbdbdb;
          border-bottom: solid 5px transparent;
          text-decoration: none;
          color: #393738;
          &:hover{
          color: #ee7f00;
          border-bottom: solid 5px #ee7f00;
          text-decoration: none;
          }
      }
  }
}
//Sidebar
.bark-left{
  display: flex;
  flex: 0 0 250px;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: 0;
  align-self: flex-start;
  height: 100vh;
  background-color: #4c4c4c;
  border-right: solid 5px #393738;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  /*&:after{
    content: '';
    position: absolute;
    left: 246px;
    top: 14.5vh;
    border-radius: 50%;
    border-left: 10px solid transparent;
    border-right: 20px solid #ee7f00;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    webkit-box-shadow: 4px 1px 8px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 4px 1px 8px 2px rgba(0,0,0,0.2);
    box-shadow: 4px 1px 8px 2px rgba(0,0,0,0.2);
  }*/
  .content{   
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 1rem;
      img{
        margin-right: auto;
        margin-left: auto;
        padding: 10px;
      }
  }
}

// Content-type = Button
//Default Button
.tgb-btn{
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  display: block;
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: solid 1px #201f20;
  color: #201f20;
  text-align: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  &:hover{
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;

  background-color: #ee7f00;
  border: 1px solid #393738;
  color: #fafafa;
  &.authorize{
    color: #fff;
  }  
  }
  &.authorize{
    color: #ee7f00;
  }  
}
// Content-type = Form
//Default Form
.tgb-form{
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  h3{
    text-align: left;
    color: #fafafa;
  }
  textarea{
    margin-bottom: 25px;
    display: flex ;
    flex:auto ;
    background-color: #393738;
    color: #ffffff;
    padding: 20px;
    border: none;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    outline-style: solid;
    outline-color: #393738;
    outline-width: 1px;
    &:focus{
      outline-color: #ee7f00;
      background-color: #393738;
      box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
    }
  }
  textarea:focus{
    outline-color: #ee7f00;
  }
  button{
    margin-top: 20px;
  }
}
.tgb-input{
  display: block;
  width: calc(100% - 1rem); 
  background-color: transparent;
  border: none;
  border-bottom: solid 1px #393738;
  color: #fafafa;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  &::placeholder{
    padding-left: 0.5rem;
  }
  &:focus{
    box-shadow: 0 0 0 0.05rem #ee7f00;
    outline: none;
  }
}
.tgb-error{
  display: block;
  width: calc(100% - 1rem); 
  background-color: transparent;
  border: none;
  border-bottom: solid 1px #393738;
  color: #fafafa;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  &::placeholder{
    padding-left: 0.5rem;
  }
  &:focus{
    box-shadow: 0 0 0 0.05rem #393738;
    outline: none;
    border-bottom: solid 1px transparent;
  }
}
.tgb-label{
  color:#fff; 
  margin-top: 1rem;
}

// Content-type = Card
//Default Card
.vein{
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #fff;
  color: #201f20;
  border-bottom: solid 10px #474546;
  &.animated{
    transform: scale(.95);
    background-color: #474546;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    video {
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      filter: grayscale(1);
    }

    &:hover{
      transform: scale(1);
      color: #4c4c4c;
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      background-color: #fafafa;
      video {
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        filter: none !important;
      }
    }
  }
  &.vein-inverted{
    background-color: #474546 !important;
    color: #ffffff !important;
    border-bottom: none !important;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    .vein-title{
      padding: 5px 0px 5px 25px;
      font-size: 1.8rem !important;
      background-color: #393738;
      color: #ee7f00;
    }
    .vein-img{
      border-bottom: 5px solid #393738;
    }
    .vein-text{
      //border:solid 5px #393738;
      li{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }
    .vein-content{
      margin-top: 20px;
     
    }
    &:hover{
      border-bottom: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      .vein-header{
        transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
       border-bottom: solid 5px #474546;
      }
    }
  }
  &:hover{
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;

      border-bottom: solid 10px #dbdbdb;
      box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: -10px 10px rgba(0, 0, 0, 0.2);
      .vein-header{
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;

      border-bottom: solid 5px #382e33;
      }
      .vein-btn{
      transition: all 1.4s ease-in-out;
      -webkit-transition: all 1.4s ease-in-out;

      border: solid 1px #fafafa;
      border-radius: 0;
      background-color: #393738;
      color: #fafafa;
      }
  }

  .vein-header{
  color: #fafafa;
  font-size: 1.1rem;
  border-radius: 0;
  border-bottom: solid 0px #474546;
  padding-left: 0.4rem;
  background-color: #ee7f00;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
      .vein-topic{
      font-size: 1.2rem !important;
      font-weight: 400;
      line-height: 1;
      p{
        margin: 10px;
      }
      }
  }
  img{
    max-width: 100%;
    height: auto;
  }
  video{
    max-width: 100%;
    height: auto;
    background-color: #474546;
    &:focus{
      outline:none;
      border:none;
    }
  }
  .vein-content{
  flex: 1 1 auto;
  padding: 1.25rem;
      .vein-title{
      font-size: 1.2rem;
      font-weight: 500;
      line-height: .4;
      }
      .vein-text{
      font-size: 0.9rem;
      line-height: 1.5;
      }
  }
  .vein-footer{
  padding: 1.2rem;
  padding-top:0; 
  }
  .vein-btn{
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;

  display: block;
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: solid 1px #201f20;
  color: #201f20;
  text-align: center;
  padding: 0.5rem;
      &:hover{
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;

      background-color: #ee7f00;
      border: 1px solid #fafafa;
      color: #fafafa;
      }
  }
}
//forign styles needs adjustment
.story-table{
  border: solid 1px #393738;
  ul{
    margin: 0;
    padding-top:    5px;
    padding-left:   25px;
    padding-right:  25px;
    color: #fafafa;
    li{
      color: #757575;
      padding: 20px;
      list-style: none;
      margin-bottom: 25px;
      background-color: #393738;
    }
  }
}



// BurgerMenu
.burgerMenu { 
    display: none;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border: none;
    background: none;
    width: 14px;
    height: 40px;
    outline: none;
    position: absolute;
    left: 252px;
    top: 184px;
    background-color: #393738;
}

.line {
  border-radius: 50%;
  position: absolute;
  height: 5px;
  width: 5px;
  background: #838383;
  transition: 0.5s;
  transform-origin: center;
  margin-left: 8px;
}

.line:nth-child(1) { top: 10px; }
.line:nth-child(2) { top: 18px; }
.line:nth-child(3) { top: 25px; }

.burgerMenu.checked .line:nth-child(1){
  transform: translateY(12px) rotate(-45deg);
}

.burgerMenu.checked .line:nth-child(2){
  opacity:0;
}

.burgerMenu.checked .line:nth-child(3){
  transform: translateY(-12px) rotate(45deg);
}
.burgerMenu.checked{
  left: 199px;
  width: 45px;
  background-color: #474546;
  padding-right: 0 !important;
  height: 40px;
  border-top: 5px solid #393738;
  border-left: 5px solid #393738;
  border-bottom: 5px solid #393738;
  .line{
    background: #ee7f00 !important;
    border-radius: 0;
    position: absolute;
    height: 3px;
    width: 15px;
    background: #838383;
    transition: 0.5s;
    transform-origin: center;
  }
}